.App {
  text-align: center;
  min-height: 100vh;
  overflow-x: hidden;
}

footer {
  border-top: 3px solid black;
  /* height: 100px; */
  background-color: #0873b9;
  display: flex;
  flex-direction: row;
}

.footer-div {
  display: flex;
  height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;
}

button {
  background-color: #0195f1;
  border-radius: 6px;
  font-size: 16px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
  border: none;
  transition: .1s ease;
}

button:hover {
  cursor: pointer;
  background-image: linear-gradient(rgb(0 0 0/6%) 0 0);
  transition: .1s ease;

}

#info-header,
#header {
  border-bottom: 3px solid black;
}

#connect,
#support,
#header,
#instructions,
.support-table,
#info-header,
#sensor-landing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-container,
.info-header-container {
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

#name {
  font-size: 40px;
}

.support-table {
  margin: 20px auto;
}


.instructions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 25px;
}

.instructions-connect {
  max-width: 450px;
  font-size: 25px;
}

.connect-desc {
  font-size: 20px;
}

.instructions-gif {
  padding: 25px 50px;
}

.connect-btn {
  margin-top: 20px;
  padding: 20px 50px !important;
  font-size: 35px !important;
}

.connect-btn:hover {
  cursor: pointer;
}

.options {
  display: flex;
  gap: 10px;

}



.options img:hover {
  cursor: pointer;
}

.bluetooth-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}



.sensors-container {
  margin-bottom: 10px;
}

.sensors-container button {
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 5%;
  max-width: 100px;
  max-height: 50px;
}

.sensors-container button:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.chart-item {
  padding: 50px;
}

.sensor-select {
  padding: 50px;
  margin-top: 10px;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.s-number,
.ip,
.reboot,
.broker-ip,
.broker-port,
.broker-status {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 10px;
}

.header-buttons {
  width: 50px;
  height: 50px;
  filter: invert(32%) sepia(48%) saturate(4909%) hue-rotate(186deg) brightness(86%) contrast(94%);
}

.header-buttons.power {
  filter: invert(24%) sepia(88%) saturate(4718%) hue-rotate(350deg) brightness(105%) contrast(99%);
}

.header-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: rgb(226, 226, 226);
}

.option-button {
  background-color: #e4e7eb;
  color: black;
  padding: 7px;
}

.option-run {
  background-color: #e4e7eb;
  color: black;
}

.sensor-buttons {
  padding: 10px;
}

input {
  padding: 5px;
  font-size: 16px;
  margin-top: 2px;
  border-radius: 4px;
  border-color: #b3b3b3;
  /* box-shadow: 2px 2px 1px 0px black; */

}

label {
  text-align: left;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

input:focus {
  border-color: #0195f1;
  /* box-shadow: 2px 2px 1px 0px #0195f1; */

}

.device-info-net,
.device-info-main {
  border: 1px solid black;
  padding: 20px;
  border-radius: 4px;
  transition: .1s ease;

}

.device-info-net:hover {
  cursor: pointer;
  z-index: 100;
  background-color: white;
  transform: scale(1.1);
  border: 1px solid #0873b9;
  box-shadow: 5px 5px 10px black;
  transition: .1s ease;
}

.edit-icon {
  height: 15px;
  filter: invert(32%) sepia(48%) saturate(4909%) hue-rotate(186deg) brightness(86%) contrast(94%);

}

.edit-title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.drop-down-button {
  background-color: transparent;
}

.drop-down-container {
  display: flex;
  background-color: rgb(226, 226, 226);
  border-radius: 4px;
}

.options-drop-down {
  z-index: 999999;
  position: absolute;
  height: fit-content;
  text-align: start;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  background-color: rgb(226, 226, 226);
  gap: 2px;
}

.drop-down-option {
  border: 1px solid black;
  padding: 15px 25px;
  border-radius: 4px;
  background-color: #0195f1;
  color: white;
  font-size: 16px;
  font-weight: 550;

}

.drop-down-option:hover {
  cursor: pointer;
}

.info-header-container {
  flex-direction: row;
  justify-content: space-between;
}

.options,
.device-name-container {
  width: 450px;
  display: flex;
  justify-content: center;
}

.sensor-selector {
  padding: 20px;
  font-size: 25px;
  border-radius: 4px;
}



.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  text-align: center;
}

.settings-upper,
.settings-lower {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.edit {
  margin-top: 20px;
}

.save button,
.reboot button,
.edit button {
  padding: 12px 13px;
}

.reboot.cancel button {
  padding: 12px 13px;


}

.bluetooth-img:hover {
  cursor: pointer;
}

#device-modal,
#mqtt-modal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.modal-container {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  max-height: 600px;
  box-shadow: 7px 9px 20px 6px black;
  overflow-y: auto;
}

.ModalOpen {
  overflow: hidden;
}

.is-hidden {
  display: none;
}

.is-visuallyHidden {
  opacity: 0;
}

.modal-title {
  font-size: 20px;
  margin-top: 10px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.form-container {
  width: 55%;
}

.footer {
  width: 100%;
  position: absolute;
  margin: 0 auto;
  display: flex;
  background-color: rgb(255, 255, 149);
  justify-content: space-around;
}

.sharc-ocean-link {
}

.reboot-container {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  gap: 25px;
  width: 100%;
  justify-content: center;
}

.reboot.cancel button,
.go-back {
  background-color: rgb(255, 31, 31) !important;
}

.support-container {
  padding: 25px;
  border: 1px solid black;
  border-radius: 8px;
}

.support-title {
  font-size: 30px;
  font-weight: bold;
}

.support-body {
  margin-top: 15px;
  font-size: 20px;
}

.chart-item {
  text-decoration: underline;
  font-size: 25px;
}

.part-count {
  font-size: 75px;
}

.recharts-wrapper {
  margin: 0 auto;
}

.save-reboot {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 20px;
}

.modal-warning {
  font-size: smaller;
}

.exit {
  background-color: #fefefe;
  /* position: relative; */
  font-size: large;
  font-weight: bold;
  left: 140px;
  border: 1px solid black;
  box-shadow: 3px 3px 5px;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 4px;
  color: black;
}

.exit.hidden,
.save.hidden {
  opacity: 0;
}

.exit.hidden:hover,
.save.hidden {
  cursor: default;
}

.exit:hover {
  cursor: pointer;
  box-shadow: 1px 2px 2px black;
}



.start-info {
  font-size: 24px;
}

.start-arrow {
  font-size: 30px;
}

.charts-error {
  padding: 25px;
  border-radius: 4px;
  border: 2px solid black;
  width: fit-content;
  margin: 0 auto;
  font-size: 50px;
  margin-top: 50px;
}

.charts-error-bottom {
  margin-top: 5px;
  font-size: 20px;
}

#device-info {
  padding: 25px;
  display: flex;
  justify-content: center;
  gap: 100px;
  /* flex-wrap: wrap; */
  /* align-items: flex-start; */
}

.device-info-title {
  width: fit-content;
  font-size: 27px;
  font-weight: 500;
  margin: 0 auto;
}

.device-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.device-info-information {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  font-size: 18px;
}

.device-info-name {
  display: flex;
  justify-content: flex-end;
}

.device-info-desc {
  display: flex;
  justify-content: flex-end;
}

.reboot-container-options {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 20px;
}

.recharts-responsive-container {
  max-width: 1000px !important;
}

.settings-information {
  display: flex;
  justify-content: center;
  gap: 200px;
  align-items: flex-start;
  font-size: 18px;
}

.settings-title {
  text-align: start;
  width: fit-content;
  font-size: 27px;
  font-weight: 500;
  position: relative;
  align-self: flex-start;
}

.charts-container {
  display: flex;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px 0;
  flex-direction: column;

}

.recharts-wrapper {
  border: 1px solid black;
  padding: 10px;
  /* border-top: 1px solid black; */
}

.charts-information {
  /* border: 1px solid black; */
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 10px;
  gap: 40px;
  /* padding: 50px; */
  font-size: 22px;
  border-radius: 3px;
  width: 400px;
  /* height: 50px; */
  justify-content: center;
}

.charts-info-item {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.charts-value-header {
  text-decoration: underline;
}

.mode-question {
  font-size: 30px;
  margin: 10px;
}

.loading {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode-button {
  height: 80px !important;
  width: 200px !important;
  font-size: 24px;

}

.mode-button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.go-back {
  padding: 15px;
  margin: 10px;
  border: none;
}

.no-data {
  font-size: 40px;
}

.device-dropdown {
  margin: 25px;
  font-size: 20px;
  padding: 20px;
}

.device-name-container {
  font-size: 20px;
}

.device-name-title {
  font-size: 25px;
  /* text-decoration: underline; */
  font-weight: 550;
}

.device-name {
  font-weight: 600;
  text-decoration: none;
  color: #0873b9;
}

.execute-container {
  display: flex;
  max-width: 95%;
  margin: 0 auto;
  max-height: 650px;
}

.execute-options,
.execute-messages-container {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.execute-options-title {
  padding: 10px;
  font-size: 20px;
}

.execute-options-container {
  overflow-y: auto;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* border: 1px solid #0195f1; */
}

.execute-editing-header-container {
  gap: 10px;
}

.execute-editing-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.execute-editing-header {
  display: flex;
  gap: 20px;
  padding: 10px;
}

.execute-editing-header-container {
  display: flex;
}

.editing-header-values-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.execute-editor {
  flex-grow: 1;
}

.execute-messages-list {
  overflow-y: auto;
}

.execute-messages-name {
  font-size: 20px;
  padding: 10px;
}

.message-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  max-width: 300px;
}

.message-container-header {
  display: flex;
  justify-content: space-between;
}

.message-payload {
  max-height: 100px;
  overflow-y: auto;
  padding: 25px;
  text-align: left;
}

.message-direction {
  text-align: start;
}

textarea {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px;
}

/* MEDIA QUERY */

@media only screen and (max-width: 600px) {
  #device-info {
    flex-direction: column;
    gap: 20px;
    padding-top: 0px;
  }

  .info-header-container {
    flex-direction: column;
  }

  .device-info-title {
    margin: 0 auto;
  }

  .no-data {
    padding: 25px;
    font-size: 30px;
  }

  .settings-information {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .execute-container {
    gap: 10px;
    flex-direction: column;
  }

  .execute-options {
    max-height: 200px;
  }

  .execute-options-title {
    padding: 5px;
  }

  .execute-editing-header {
    justify-content: start;
  }

  .execute-editing-button-value {
    overflow-wrap: anywhere;
    max-width: 160px;
  }

  .execute-editor {
    height: 250px;
  }

  .execute-messages-container {
    padding-bottom: 25px;
  }

  .execute-messages-list {
    margin: 0 auto;
    max-height: 250px;
  }

  .options-drop-down {
    right: 10px;
  }

}

@media only screen and (max-width: 900px) {
  .recharts-wrapper {
    width: 90% !important;
  }

  .recharts-surface {
    height: 50%;
    width: 100%;
  }

  #device-info {
    flex-direction: column;
    gap: 20px;
    padding-top: 0px;
  }

  .charts-container {
    /* flex-direction: column-reverse; */
    padding-top: 30px;
  }

  .charts-information {
    padding: 20px;
  }

  .execute-container {
    gap: 10px;
    flex-direction: column;
  }

  .execute-options {
    max-height: 200px;
  }

  .execute-options-title {
    padding: 5px;
  }

  .execute-editing-header {
    justify-content: start;
  }

  .execute-editing-button-value {
    overflow-wrap: anywhere;
    max-width: 160px;
  }

  .execute-editor {
    height: 250px;
  }

  .execute-messages-container {
    padding-bottom: 25px;
  }

  .execute-messages-list {
    margin: 0 auto;
    max-height: 250px;
  }

  #logo {
    width: 80% !important;
  }

  html {
    overflow-x: hidden;
  }


}

@media only screen and (max-width: 1300px) {
  .info-header-container {
    flex-direction: column;
  }

}

.device-name-container {
  flex-direction: column;
  font-size: 30px;
}

#trapezoid {
  border-bottom: 100px solid black;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  height: 0;
  width: 200px;
  transform: rotate(180deg);
}

.connected-sharc {
  color: white;
  transform: rotate(180deg);
  position: relative;
  top: 43px;
}

.trapezoid-container {
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
}

.modal-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.gen-config-name {
  width: 75px;
}

.gen-config,
.npn-config,
.pnp-config {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.gen-config-title {
  font-weight: 550;
}

.sensor-landing-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.gen-config-option {
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}

.config-help {
  width: 25px;
  height: 25px;
}

.config-help-button {
  background-color: white;
  display: flex;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.config-dropdown {
  background-color: #eeeeee;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: transparent;
}

.period-input {
  width: 100px;
  overflow-x: auto;
}

.modal-close {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modbus-registers-options {
  margin-top: 5px;
}

@media only screen and (max-width: 1700px) {
  #device-info {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1500px) {

  .charts-container {
    flex-wrap: wrap-reverse;
    justify-content: center;
    padding-top: 50px;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}